import React from 'react';

import { Link } from '@/i18n/routing';
import { useTranslations } from 'next-intl';

const GoHomePageButton = () => {
  const t = useTranslations('Errors');
  return (
    <Link
      href="/"
      className="inline-flex w-full items-center justify-center rounded-none bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
    >
      {t('goToHomepage')}
    </Link>
  );
};

export default GoHomePageButton;
