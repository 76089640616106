import { Link } from '@/i18n/routing';
import { useTranslations } from 'next-intl';

import { Button } from '@repo/ui/button';

import GoHomePageButton from './GoHomePageButton';

interface props {
  error: Error & { digest?: string };
  reset: () => void;
}

export function ErrorMessage({ error, reset }: props) {
  const t = useTranslations('Errors');

  return (
    <div className="flex flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="mx-auto h-12 w-12 text-primary" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          {t('somethingWentWrong')}
        </h1>
        <p className="mt-4 text-muted-foreground">{t('weAreSorry')}</p>
        <pre className="mt-4 whitespace-pre-wrap text-center text-sm text-red-500">
          {t('errorMessage', { error: error.message })}
        </pre>
        <div className="mt-6 flex flex-col items-center gap-2 sm:flex-row sm:justify-center">
          <div className="sm:w-fit">
            <GoHomePageButton />
          </div>
          <Button
            onClick={reset}
            variant="outline"
            className="w-full sm:w-auto"
          >
            {t('tryAgain')}
          </Button>
        </div>
      </div>
    </div>
  );
}
